.game-square {
    width: 8rem;
    height: 8rem;
    display: inline-flex;
    font-size: 80px;
    justify-content: center;
}

.board-row {
    display: flex;
}

.age-guesser {
    max-width: 25rem;
}

.game-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.input {
    border-color: rgba(104, 51, 185, 0.512);
    border-radius: 5px;
    padding: 5px;

    &:hover {
        background-color: rgb(245, 240, 250);
    }

    &:focus {
        background-color: rgb(245, 240, 250);
    }
}


@media only screen and (max-width: 900px) {
    .game-square {
        width: 5rem;
        height: 5rem;
        font-size: 50px;
    }

    .game-row {
        flex-direction: column;
    }
}