:root {
    background-color: var(--bs-body-bg);
    --bs-btn-bg: var(--bs-primary);

}

.content {
    background-color: var(--bs-body-bg);
}

.header {
    background-color: var(--bs-primary);
}

.search-bar {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.search-bar-btn {
    margin-left: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 4px;
    color: var(--bs-body-color);
    background-color: var(--bs-primary);
    border: 1px white;
}

.light {
    --bs-primary: #a88bf0;
    --bs-body-bg: #ece8f9;
    --active-nav-link: #5a4395;
    --hover-link: #4f3b81;
}

.dark {
    --bs-primary: #4a3383;
    --bs-body-bg: #725da7;
    --bs-body-color: white;
    --nav-link: white;
    --active-nav-link: #a88bf0;
    --hover-link: #ccb9fc;

    .header {
        color: white;
    }
}


.choice {
    --active-nav-link: rgb(from var(--bs-body-color) r g b / .65);
    // --active-nav-link: darken(red, 50%);
    --hover-link: rgb(from var(--bs-body-color) r g b / .50);
    // --hover-link: adjust-hue(var(--bs-body-color), 80deg)
}