html,
body,
div#root,
.page-root {
    height: 100%;
    background-color: var(--bs-body-bg);
    color: var(--bs-body-color);
    min-width: fit-content;

    .main-content {

        .content {
            padding: 1rem;
            
        }
    }

}

@media screen {
    .print-only {
        display: none;
    }
}

.weather-widget {
    border: thick double rgba(0, 0, 0, 0.559);
    width: 20rem;
    margin: 3rem;
    margin-left: .5rem;
    text-align: center;
}

.widget-title {
    border-bottom: 2px;
    text-align: center;
}


@media only screen and (max-width: 900px) {
    .page-root {
        font-size: 15px;

        .weather-widget {
            width: 18rem;
        }

        .big-facts {
            max-width: 20rem;
            text-align: center;

            .flags {
                flex-direction: column;
            }
        }

        .country-facts {
            padding-left: 0;
            max-width: 20rem;
        }

        .country-header {
            display: flex;
            flex-direction: column;
            max-width: 20rem;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .country-facts {
        max-width: 35rem;
    }
}

.country-facts {
    width: 45rem;
}

.big-facts {
    max-width: 45rem;
    text-align: center;
    align-self: center !important;

    .flags {
        justify-content: space-between;
        display: flex;
        flex-direction: row;
    }
}

.country-header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.custom-link {
    color: var(--bs-body-color);
}

.old-img {
    opacity: 50%;
} 

.new-img {
    border: 2px ridge var(--bs-primary);
}

.clickable-text {
    font-weight: 700;
}

.clickable-text:hover {
    cursor: pointer;
}