.image-container {
    height: 9rem;
    width: 9rem;
    display: inline-block;
    margin: .4rem;

    .image-thumbnail {
        filter: grayscale(10%);
        filter: brightness(90%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;

    
        &:hover {
            cursor: pointer;
            transform: scale(1.1);
        }
    }
}

@media only screen and (max-width: 900px) {
    .image-container {
        height: 6.5rem;
        width: 6.5rem;
        margin: .2rem;
        

        .image-thumbnail {

            &:hover {
                cursor: pointer;
                transform: scale(1.1);
            }
        }
    }
}
